export default [
  {
    title: 'Gestion locative',
    icon: 'ListIcon',
    children: [
      {
        title: 'Tableau de bord & suivi',
        route: 'espace-admin-syndic.dashboard',
        icon: 'HomeIcon',
      },
      {
        title: 'Administration',
        icon: 'ListIcon',
      },
      {
        title: 'Publication d\'offres',
        icon: 'ListIcon',
        children: [
          {
            title: 'Les biens immobiliers actifs',
            route: 'espace-admin-syndic.proprietes',
            color: 'secondary',
            icon: 'DiscIcon',
          },
          {
            title: 'Les biens immobiliers suspendues',
            route: 'espace-admin-syndic.proprietes-suspendues',
            color: 'secondary',
            icon: 'DiscIcon',
          },
        ],
      },
      {
        title: 'Comptabilité',
        icon: 'ListIcon',
      },
      {
        title: 'Rapport & analyses',
        icon: 'ListIcon',
      },

    ],
  },
  {
    title: 'Gestion de copropriété',
    icon: 'ListIcon',
    children: [
      {
        title: 'Administration & finances',
        icon: 'DiscIcon',
        children: [
          {
            title: 'Ma copropriété',
            route: 'espace-admin-syndic.copropriete',
            icon: 'DiscIcon',
          },
          {
            title: 'Membre Copropriété',
            route: 'espace-admin-syndic.staff',
            icon: 'DiscIcon',
          },
          {
            title: 'Frais de copropriété',
            route: 'espace-admin-syndic.frais-copropriete',
            icon: 'DiscIcon',
          },
        ],
      },
      {
        title: 'Opérations',
        icon: 'DiscIcon',
        children: [
          // {
          {
            title: 'Types parties communes',
            route: 'espace-admin-syndic.type-parties-communes',
            icon: 'DiscIcon',
          },
          {
            title: 'Parties communes',
            route: 'espace-admin-syndic.parties-communes',
            icon: 'DiscIcon',
          },
          // {
          //   title: 'Type de tâche',
          //   route: 'espace-admin-syndic.type-de-taches',
          //   icon: 'DiscIcon',
          // },
          // {
          //   title: 'Tâche d\'entretien',
          //   route: 'espace-admin-syndic.taches-entretien',
          //   icon: 'DiscIcon',
          // },
          // title: 'Planification des tâches',
          // icon: 'DiscIcon',
          // children: [

          // ],
          // },
          {
            title: 'Tâches d\'entretien',
            route: 'espace-admin-syndic.taches-entretien-planifie',
            icon: 'DiscIcon',
          },
        ],
      },
      {
        title: 'Communication',
        icon: 'DiscIcon',
      },
      {
        title: 'Rapport & analyses',
        icon: 'DiscIcon',
      },
      {
        title: 'Accès & sécurité',
        icon: 'DiscIcon',
      },
    ],
  },

]
